import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import AuthInnerNavbar from "src/component/navbar/authInnerNavbar";
import { LOGOUT } from "src/component/helper/constants";
import { useDispatch } from "react-redux"; 

const AgentOnboardingReportScreen = lazy(
  () => import("src/pages/innerPages/report/agentOnboardingReport/agentOnboardingReportScreen")
);

const AccountOpenReportScreen = lazy(
  () => import("src/pages/innerPages/report/accountOpenReport/accountOpenReportScreen")
);
const AgentDashboardScreen = lazy(
  () => import("src/pages/innerPages/dashborad/agent/agentDashboardScreen")
);

const AEPSLandingScreen = lazy(
  () => import("src/pages/innerPages/aeps/aepsLandingScreen")
);

const AgentAccountOpen = lazy(
  () => import("src/pages/innerPages/agentAccountOpen/agentAccountOpen")
);

const AccountOpenSettingScreen = lazy(
  () =>
    import("src/pages/innerPages/accountOpenSetting/accountOpenSettingScreen")
);
const AepsTransactionScreen = lazy(
  () => import("src/pages/innerPages/aepsTransaction/aepsTransactionScreen")
);

const AepsTwoFaScreen = lazy(
  () => import("src/pages/innerPages/aepsTwoFA/aepsTwoFaScreen")
);

const AccountOpenScreen = lazy(
  () => import("src/pages/innerPages/accountOpen/accountOpenScreen")
);

const PendingRequestScreen = lazy(
  () => import("src/pages/innerPages/fund/pendingRequest/pendingRequestScreen")
);
const AepsScreen = lazy(
  () => import("src/pages/innerPages/aepsTerminalRegister/aepsScreen")
);

const AepsReportScreen = lazy(
  () => import("src/pages/innerPages/report/aepsReport/aepsReportScreen")
);

const DmtReportScreen = lazy(
  () => import("src/pages/innerPages/report/dmtReport/dmtReportScreen")
);
const DmtSenderSearchScreen = lazy(
  () => import("src/pages/innerPages/dmtSenderSearch/dmtSenderSearchScreen")
);
const RetailerDashboardScreen = lazy(
  () => import("src/pages/innerPages/dashborad/agent/agentDashboardScreen")
);
const OnboardingAgentScreen = lazy(
  () => import("src/pages/innerPages/onboardingAgent/OnboardingAgentScreen")
);
const ChangePasswordAddScreen = lazy(
  () => import("src/pages/innerPages/change_password/changePasswordAddScreen")
);

const UserReportScreen = lazy(
  () => import("src/pages/innerPages/allUser/userReportScreen")
);

const QuickSearch = lazy(
  () => import("src/pages/innerPages/quickSearch/quickSearchScreen")
);

const AdminDashboardScreen = lazy(
  () => import("src/pages/innerPages/dashborad/admin/adminDashboardScreen")
);

const UserOnboardingScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/agentPersonalDetails/agentPersonalDetailsScreen"
    )
);

const KeySettingScreen = lazy(
  () => import("src/pages/innerPages/key_setting/keySettingScreen")
);

const MessageSettingAddScreen = lazy(
  () => import("src/pages/innerPages/message_setting/messageSettingScreen")
);

const SMSSettingAddScreen = lazy(
  () => import("src/pages/innerPages/sms/smsSettingAddScreen")
);
const DMTSettingScreen = lazy(
  () => import("src/pages/innerPages/dmt_setting/DMTSettingScreen")
);
const AEPSSettingScreen = lazy(
  () => import("src/pages/innerPages/aepssetting/aepsSettingScreen")
);



const MainRoute: React.FC = () => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(LOGOUT());
    LOGOUT();
  };

  return (
    <AuthInnerNavbar>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/Dashboard" element={<AdminDashboardScreen />} />
          <Route
            path="/RetailerDashboard"
            element={<RetailerDashboardScreen />}
          />
          <Route path="/UserOnboarding" element={<OnboardingAgentScreen />} />
          <Route path="/KeySetting" element={<KeySettingScreen />} />
          <Route path="/MessageSetting" element={<MessageSettingAddScreen />} />
          <Route path="/SMSSetting" element={<SMSSettingAddScreen />} />
          <Route path="/DMTSetting" element={<DMTSettingScreen />} />
          <Route path="/AEPSSetting" element={<AEPSSettingScreen />} />
          
          <Route path="/AccountOpenSetting" element={<AccountOpenSettingScreen />} />
          
          <Route
            path="/AccountOpenSetting"
            element={<AccountOpenSettingScreen />}
          />
          <Route path="/ChangePassword" element={<ChangePasswordAddScreen />} />
          <Route path="/Search" element={<QuickSearch />} />
          <Route path="/AllUsers" element={<UserReportScreen />} />
          <Route path="/DMT" element={<DmtSenderSearchScreen />} />
          <Route path="/DMTReport" element={<DmtReportScreen />} />
          <Route path="/AEPS" element={<AEPSLandingScreen />} />
          <Route path="/AEPSReport" element={<AepsReportScreen />} />
          <Route path="/PendingRequest" element={<PendingRequestScreen />} />
          <Route path="/AccountOpen" element={<AccountOpenScreen />} />
          <Route path="/TwoFa" element={<AepsTwoFaScreen />} />
          <Route path="/AepsTransaction" element={<AepsTransactionScreen />} />
          <Route path="/AepsRegister" element={<AepsScreen />} />
          <Route path="/AgentAcOpening" element={<AgentAccountOpen />} />
          <Route path="/AgentDashboard" element={<AgentDashboardScreen />} />
          <Route path="/OnboardingReport" element={<AgentOnboardingReportScreen />} />
          <Route path="/AccountOpenReport" element={<AccountOpenReportScreen />} />
          
          <Route
            path="/Logout"
            element={<LogoutComponent onLogout={handleLogout} />}
          />
        </Routes>
      </Suspense>
    </AuthInnerNavbar>
  );
};

export default MainRoute;

interface LogoutComponentProps {
  onLogout: () => void;
}

const LogoutComponent: React.FC<LogoutComponentProps> = ({ onLogout }) => {
  React.useEffect(() => {
    onLogout();
  }, [onLogout]);

  return <div>Logging out...</div>;
};
