import { useTranslation } from "react-i18next";

export const GetMenuItemTranslation = (itemName: string) => {
  const { t } = useTranslation("mainMenuList");
  const translations: Record<string, { text: string; icon: string }> = {
    Dashboard: { text: t("Dashboard"), icon: "MainMenu/home.svg" },
    Users: { text: t("Users"), icon: "MainMenu/master.svg" },
    Service: { text: t("Service"), icon: "MainMenu/master.svg" },
    Search: { text: t("Search"), icon: "MainMenu/master.svg" },
    Setting: { text: t("Setting"), icon: "MainMenu/setting.svg" },
    Fund: { text: t("Fund"), icon: "MainMenu/loan.svg" },
    Products: { text: t("Products"), icon: "MainMenu/expense.svg" },
    Report: { text: t("Report"), icon: "MainMenu/report.svg" },
  };
  const translation = translations[itemName] || { text: itemName, icon: "" };
  return translation;
};

export const GetSubMenuItemTranslation = (itemName: string) => {
  const { t } = useTranslation("subMenuList");
  const translations: Record<string, { text: string; icon: string }> = {
    "All Users": { text: t("All Users"), icon: "MainMenu/product-type.svg" },
    "User Onboarding": {
      text: t("User Onboarding"),
      icon: "MainMenu/product-type.svg",
    },
    "DMT Setting": {
      text: t("DMT Setting"),
      icon: "MainMenu/product-type.svg",
    },
    "Account Open Setting": {
      text: t("Account Open Setting"),
      icon: "MainMenu/product-type.svg",
    },
    "AEPS Setting": {
      text: t("AEPS Setting"),
      icon: "MainMenu/product-type.svg",
    },
    "Payout Setting": {
      text: t("Payout Setting"),
      icon: "MainMenu/product-type.svg",
    },
   
    "Key Setting": { text: t("Key Setting"), icon: "MainMenu/key-setting.svg" },
    "Message Setting": {
      text: t("Message Setting"),
      icon: "MainMenu/message-setting.svg",
    },
    "SMS Setting": { text: t("SMS Setting"), icon: "MainMenu/sms-setting.svg" },
    "Pending Request": {
      text: t("Pending Request"),
      icon: "MainMenu/product-type.svg",
    },
    "Request Raise": {
      text: t("Request Raise"),
      icon: "MainMenu/product-type.svg",
    }, 
    "Add Money": {
      text: t("Add Money"),
      icon: "MainMenu/product-type.svg",
    },
    "DMT": {
      text: t("DMT"),
      icon: "MainMenu/product-type.svg",
    },
    "AEPS": {
      text: t("AEPS"),
      icon: "MainMenu/product-type.svg",
    },
    "DMT Report": {
      text: t("DMT Report"),
      icon: "MainMenu/product-type.svg",
    },
    "AEPS Report": {
      text: t("AEPS Report"),
      icon: "MainMenu/product-type.svg",
    },
    "A/c Opening": {
      text: t("A/c Opening"),
      icon: "MainMenu/product-type.svg",
    },
    "Agent A/c Opening": {
      text: t("Agent A/c Opening"),
      icon: "MainMenu/product-type.svg",
    },
    "A/c Open Report": {
      text: t("A/c Open Report"),
      icon: "MainMenu/product-type.svg",
    },
    "Onboarding Report": {
      text: t("Onboarding Report"),
      icon: "MainMenu/product-type.svg",
    },
   
  };
  const translation = translations[itemName] || itemName;
  return translation;
};
