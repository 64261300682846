import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import AuthLayout from "src/component/navigation/publicNavbar";

const LoginScreen = lazy(
  () => import("src/pages/outerPages/login/loginScreen")
);

const HomeScreen = lazy(() => import("src/pages/outerPages/home/home"));

const AuthRoute: React.FC = () => {
  return (
    <AuthLayout>
      <Suspense>
        <Routes>
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/home" element={<HomeScreen />} />
          <Route path="/" element={<HomeScreen />} />
        </Routes>
      </Suspense>
    </AuthLayout>
  );
};

export default AuthRoute;
