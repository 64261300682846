import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonIcon from "@mui/icons-material/Person";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { LOGOUT } from "src/component/helper/constants";
import { useNavigate } from "react-router";
import { Logout } from "@mui/icons-material";
import { Grid, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18n from "src/component/util/languageResource";
import { languageOptions } from "src/component/util/staticDropdownList";

import { COMPANY, MEMBER, SUPPORT } from "src/component/helper/userTypeCode";

import UseCompanyDashboard from "src/pages/innerPages/dashborad/admin/useAdminDashboard";

import { useAppSelector } from "src/redux/hooks";
export default function AccountMenu() {
  const userInfo = useAppSelector((state) => state.user.userInfo);

  const [UserAvaterImagePath] = useState(userInfo.ProfileImagePath);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlelogout = () => {
    dispatch(LOGOUT());
    LOGOUT();
  };

  const handleMenu = (menuUrl: string) => {
    navigate(menuUrl);
    handleClose();
  };
  const { t } = useTranslation("accountMenu");
  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };
  return (
    <>
      <Grid container>
        <Grid item lg={12}>
          <Box sx={{ justifyContent: "space-between", display: "flex" }}>
            <Box
              sx={{
                fontSize: "18px",
                fontWeight: "550",
                color: "#262626",
                justifyContent: "space-between",
                display: "flex",
                marginLeft: "auto",
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  textAlign: "center",
                  marginLeft: "10px",
                }}
              >
                <Select
                  labelId="language-select-label"
                  style={{padding:'0px'}}
                  id="language-select"
                  value={i18n.language}
                  label="Language"
                  onChange={(e) => changeLanguage(e.target.value)}
                >
                  {languageOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <Avatar
                      sx={{ width: 40, height: 40 }}
                      src={
                        UserAvaterImagePath
                          ? UserAvaterImagePath
                          : "/profile_icon.png"
                      }
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box sx={{ p: "10px", textAlign: "center", justifyContent: "center" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Avatar
              sx={{ width: 100, height: 100 }}
              src={
                UserAvaterImagePath ? UserAvaterImagePath : "/employee_icon.png"
              }
            />
          </Box>
          <Typography
            variant="h6"
            sx={{
              color: "#32475C",
              mt: "10px",
              fontSize: "20px",
              fontWeight: "550",
              fontFamily: "Poppins",
            }}
          >
            {userInfo.userName}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "#32475C", fontFamily: "Poppins", fontWeight: "550" }}
          >
            {userInfo.UserType}
          </Typography>
        </Box>
        <Divider sx={{ mb: "8px" }} />
        <MenuItem
          onClick={() => handleMenu("/Profile")}
          sx={{
            fontFamily: "Poppins",
            fontWeight: "550",
            fontSize: "16px",
            color: "#000",
          }}
        >
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>{" "}
          {t("Profile")}
        </MenuItem>
        <MenuItem
          onClick={() => handleMenu("/ChangePassword")}
          sx={{
            fontFamily: "Poppins",
            fontWeight: "550",
            fontSize: "16px",
            color: "#000",
          }}
        >
          <ListItemIcon>
            <LockOpenIcon fontSize="small" />
          </ListItemIcon>
          {t("Change Password")}
        </MenuItem>
        <Divider />

        <MenuItem
          onClick={() => handlelogout()}
          sx={{
            fontFamily: "Poppins",
            fontWeight: "550",
            fontSize: "16px",
            color: "#000",
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" sx={{ color: "#ff0000" }} />
          </ListItemIcon>
          {t("Logout")}
        </MenuItem>
      </Menu>
    </>
  );
}
